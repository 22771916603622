import Mock from "../mock";

const TransferList = [
  {
    id: 1,
    imgUrl: "/assets/images/products/facebook.jpg",
    name: "Facebook Insights",
    transfer: "Facebook Insights Prod Daily Transfer",
    transferImgUrl: "/assets/images/products/SFTP.jpg",
    destination: "SFTP",
    accounts: "2644397570",
    status: "green",
    query: "clientInformation.stores.all(get_..."
  },
  {
    id: 2,
    imgUrl: "/assets/images/products/insta.jpg",
    name: "Instagram Ads",
    transfer: "Instagram Ads Prod Weekly Transfer",
    transferImgUrl: "/assets/images/products/SFTP.jpg",
    destination: "SFTP",
    accounts: "2644397923",
    status: "green",
    query: "clientInformation.stores.all(get_..."
  },
  {
    id: 3,
    imgUrl: "/assets/images/products/GoogleAds.jpg",
    name: "Google Ads",
    transfer: "Google Ads Prod Daily Transfer",
    transferImgUrl: "/assets/images/products/SFTP.jpg",
    destination: "SFTP",
    accounts: "2644397717",
    status: "red",
    query: "clientInformation.stores.all(get_..."
  },
  {
    id: 4,
    imgUrl: "/assets/images/products/GAnalytics.jpg",
    name: "Google Analytics",
    transfer: "Google Analytics Prod Daily Transfer",
    transferImgUrl: "/assets/images/products/SFTP.jpg",
    destination: "SFTP",
    accounts: "2644397326",
    status: "green",
    query: "clientInformation.stores.all(get_..."
  },
  {
    id: 5,
    imgUrl: "/assets/images/products/LinkedIn.jpg",
    name: "LinkedIn Ads",
    transfer: "LinkedIn Ads Prod Daily Transfer",
    transferImgUrl: "/assets/images/products/SFTP.jpg",
    destination: "SFTP",
    accounts: "2644397229",
    status: "red",
    query: "clientInformation.stores.all(get_..."
  }
];

Mock.onGet("/api/TransferList").reply(() => {
  const response = TransferList;
  return [200, response];
});
