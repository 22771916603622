import { useCallback, useEffect, useState } from "react";
import { Avatar, Box, Divider, IconButton, styled, TextField, useTheme } from "@mui/material";
import { Clear } from "@mui/icons-material";
import ScrollBar from "react-perfect-scrollbar";
import { H5, H6, Span } from "./Typography";
import { ChatAvatar } from "app/themecomponents";
import { convertHexToRGB } from "app/utils/utils";
import axios from "axios";
import Papa from "papaparse";
import "./Chatbox.css";

// STYLED COMPONENTS
const ChatContainer = styled("div")({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  background: "#fff"
});

const StyledScrollBar = styled(ScrollBar)({
  flexGrow: 1
});

const ProfileBox = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "12px 12px 12px 20px",
  color: theme.palette.primary.main,
  background: "#2d354d"
}));

const ChatStatus = styled("div")(({ theme }) => ({
  marginLeft: "12px",
  color: theme.palette.primary.main,
  "& h5": {
    marginTop: 0,
    fontSize: "14px",
    marginBottom: "3px"
  },
  "& span": { fontWeight: "500" }
}));

const ChatMessage = styled("div")(({ theme }) => ({
  padding: "8px",
  maxWidth: 240,
  fontSize: "14px",
  borderRadius: "4px",
  marginBottom: "8px",
  whiteSpace: "pre-wrap",
  wordBreak: "break-word",
  color: theme.palette.primary.main,
  background: "#fafafa"
}));

const MessageTime = styled("span")(({ theme }) => ({
  fontSize: "13px",
  fontWeight: "500",
  color: theme.palette.primary.main
}));

const ChatImgContainer = styled("div")({
  padding: "20px",
  display: "flex",
  justifyContent: "flex-end"
});

const ChatImgBox = styled("div")(({ theme }) => ({
  padding: "8px",
  fontSize: "14px",
  maxWidth: 240,
  borderRadius: "4px",
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  color: theme.palette.primary.main,
  background: "#fafafa"
}));

const ChatImg = styled("img")(() => ({ width: "40px" }));

// for previewing bot message
const globalMessageList = [];

export default function Chatbox({ togglePopup }) {
  const [isAlive, setIsAlive] = useState(true);
  const [message, setMessage] = useState("");
  const [messageList, setMessageList] = useState([]);
  const currentUserId = "7863a6802ez0e277a0f98534";
  const chatBottomRef = document.querySelector("#chat-scroll");

  //--------------------------------------------------------------------
  const [messages, setMessages] = useState([
    {
      message: "Hello, I'm ChatGPT! Ask me anything!",
      sender: "ChatGPT",
      timestamp: new Date().toLocaleTimeString()
    }
  ]);
  const [inputMessage, setInputMessage] = useState("");
  const [isTyping, setIsTyping] = useState(false);

  const handleSend = async () => {
    if (inputMessage.trim() === "") return;

    const newMessage = {
      message: inputMessage,
      sender: "user",
      timestamp: new Date().toLocaleTimeString()
    };

    setMessages([...messages, newMessage]);
    setInputMessage("");
    setIsTyping(true);

    const response = await axios.post("http://127.0.0.1:5000/chat", { prompt: inputMessage });
    const responseMessage = {
      message: response.data.response,
      sender: "ChatGPT",
      timestamp: new Date().toLocaleTimeString()
    };

    setMessages([...messages, newMessage, responseMessage]);
    setIsTyping(false);
  };

  const handleCSVUpload = (event) => {
    const file = event.target.files[0];
    Papa.parse(file, {
      header: true,
      complete: async (results) => {
        const firstRow = results.data[0];
        const prompt = Object.values(firstRow).join(" ");

        const newMessage = {
          message: prompt,
          sender: "user",
          timestamp: new Date().toLocaleTimeString()
        };

        setMessages([...messages, newMessage]);
        setIsTyping(true);

        const response = await axios.post("http://127.0.0.1:5000/chat", { prompt });

        console.log(response);
        const responseMessage = {
          message: response.data.response,
          sender: "ChatGPT",
          timestamp: new Date().toLocaleTimeString()
        };

        setMessages([...messages, newMessage, responseMessage]);
        setIsTyping(false);
      }
    });
  };
  //--------------------------------------------------------------------

  const sendMessageOnEnter = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      let tempMessage = message.trim();
      if (tempMessage !== "") {
        let tempList = [...messageList];
        let messageObject = {
          text: tempMessage,
          contactId: currentUserId
        };
        tempList.push(messageObject);
        globalMessageList.push(messageObject);
        if (isAlive) setMessageList(tempList);
        dummyReply();
      }
      setMessage("");
    }
  };

  const dummyReply = async () => {
    setTimeout(() => {
      let tempList = [...messageList];
      let messageObject = {
        text: "Good to hear from you. enjoy!!!",
        contactId: "opponents contact id",
        avatar: "/assets/images/face-4.jpg",
        name: "Frank Powell"
      };

      tempList.push(messageObject);
      globalMessageList.push(messageObject);
      if (isAlive) setMessageList(globalMessageList);
    }, 2000);
  };

  const scrollToBottom = useCallback(() => {
    if (chatBottomRef) {
      chatBottomRef.scrollTo({
        top: chatBottomRef.scrollHeight,
        behavior: "smooth"
      });
    }
  }, [chatBottomRef]);

  useEffect(() => {
    if (isAlive) {
      fetch("/members")
        .then((res) => res.json())
        .then((data) => {
          setMessageList(data.members);
        });
    }
  }, [isAlive]);

  useEffect(() => {
    scrollToBottom();
    return () => setIsAlive(false);
  }, [messageList, scrollToBottom]);

  const { palette } = useTheme();
  const primary = palette.primary.main;
  const textPrimary = palette.text.primary;

  return (
    <ChatContainer>
      <ProfileBox>
        <Box display="flex" alignItems="center">
          <ChatAvatar src="/assets/images/face-4.jpg" status="#2fd33d" />
          <ChatStatus>
            <H5>Amber</H5>
            <Span>Active</Span>
          </ChatStatus>
        </Box>
        <IconButton onClick={togglePopup}>
          <Clear fontSize="small" style={{ color: "fafafa" }} />
        </IconButton>
      </ProfileBox>
      <StyledScrollBar id="chat-scroll">
        {messageList.map((item, ind) => (
          <Box
            key={ind}
            p="20px"
            display="flex"
            sx={{ justifyContent: currentUserId === item.contactId && "flex-end" }}
          >
            {currentUserId !== item.contactId && <Avatar src={item.avatar} />}
            <Box ml="12px">
              {currentUserId !== item.contactId && (
                <H5 mb={0.5} fontSize={14} color="#000">
                  {item.name}
                </H5>
              )}
              <ChatMessage style={{ backgroundColor: "#4267B2" }}>{item.text}</ChatMessage>
              <MessageTime style={{ color: "#8b8b8b" }}>1 minute ago</MessageTime>
            </Box>
          </Box>
        ))}
      </StyledScrollBar>

      <div>
        <Divider sx={{ background: `rgba(${convertHexToRGB(textPrimary)}, 0.15)` }} />
        <div className="messages">
          {messages.map((msg, index) => (
            <div key={index} className={`message ${msg.sender}`}>
              <div className="message-content">{msg.message}</div>
              <div className="message-timestamp">{msg.timestamp}</div>
            </div>
          ))}
          {isTyping && <div className="message typing">ChatGPT is typing...</div>}
        </div>
        <div className="input-container">
          <input
            type="text"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            placeholder="Type your message here..."
          />
          <button onClick={handleSend}>Send</button>
          <input type="file" accept=".csv" onChange={handleCSVUpload} />
        </div>
      </div>
    </ChatContainer>
  );
}
