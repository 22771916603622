import Mock from "../mock";

const ConnectorList = [
  {
    id: 1,
    imgUrl: "/assets/images/products/ADA.jpg",
    name: "Adobe Analytics",
    Activity: "Active",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "Manual",
    dialog: "AdobeAnalytics"
  },
  {
    id: 2,
    imgUrl: "/assets/images/products/GoogleAds.jpg",
    name: "Google Ads",
    Activity: "Active",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "Manual",
    dialog: "GoogleAds"
  },
  {
    id: 3,
    imgUrl: "/assets/images/products/facebook.jpg",
    name: "Facebook Ads",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "Manual",
    dialog: "FacebookAds"
  },
  {
    id: 4,
    imgUrl: "/assets/images/products/facebook.jpg",
    name: "Facebook Insights",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "Manual",
    dialog: "FacebookAds"
  },
  {
    id: 5,
    imgUrl: "/assets/images/products/GAnalytics.jpg",
    name: "Google Analytics",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "Manual",
    dialog: "GoogleAnalytics"
  },
  {
    id: 6,
    imgUrl: "/assets/images/products/GAnalytics.jpg",
    name: "Google Analytics 4",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "Manual",
    dialog: "GoogleAnalytics"
  },
  {
    id: 7,
    imgUrl: "/assets/images/products/insta.jpg",
    name: "Instagram Ads",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "Manual",
    dialog: "InstagramAds"
  },
  {
    id: 8,
    imgUrl: "/assets/images/products/LinkedIn.jpg",
    name: "LinkedIn Ads",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "Manual",
    dialog: "LinkedInAds"
  },
  {
    id: 9,
    imgUrl: "/assets/images/products/LinkedIn.jpg",
    name: "LinkedIn Pages",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "Manual",
    dialog: "LinkedInAds"
  },
  {
    id: 10,
    imgUrl: "/assets/images/products/SCM.jpg",
    name: "Snapchat Ads",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "Manual",
    dialog: "GoogleAnalytics"
  },
  {
    id: 11,
    imgUrl: "/assets/images/products/MARK.jpg",
    name: "Marketo",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "Manual",
    dialog: "Marketo"
  },
  {
    id: 12,
    imgUrl: "/assets/images/products/GSC.jpg",
    name: "Google Search Console",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "Manual",
    dialog: "GoogleAnalytics"
  },
  {
    id: 13,
    imgUrl: "/assets/images/products/GS.jpg",
    name: "Google Sheets",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "Manual",
    dialog: "GoogleAnalytics"
  },
  {
    id: 14,
    imgUrl: "/assets/images/products/BQ.jpg",
    name: "Google BigQuery",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "Manual",
    dialog: "GoogleBigQuery"
  },
  {
    id: 15,
    imgUrl: "/assets/images/products/HS.jpg",
    name: "HubSpot",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "Manual",
    dialog: "GoogleAnalytics"
  },
  {
    id: 16,
    imgUrl: "/assets/images/products/mailchimp.jpg",
    name: "MailChimp",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "Manual",
    dialog: "MailChimp"
  },
  {
    id: 17,
    imgUrl: "/assets/images/products/SLK.jpg",
    name: "Slack",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "Manual",
    dialog: "Slack"
  },
  {
    id: 18,
    imgUrl: "/assets/images/products/ST.jpg",
    name: "Stripe",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "Manual",
    dialog: "Stripe"
  },
  {
    id: 19,
    imgUrl: "/assets/images/products/MicrosoftAd.jpg",
    name: "Microsoft Advertising",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "Manual",
    dialog: "AdobeAnalytics"
  },
  {
    id: 20,
    imgUrl: "/assets/images/products/TikTok.jpg",
    name: "TikTok Ads",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "Manual",
    dialog: "AdobeAnalytics"
  },
  {
    id: 21,
    imgUrl: "/assets/images/products/TikTok.jpg",
    name: "TikTok Organic",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "Manual",
    dialog: "AdobeAnalytics"
  },
  {
    id: 22,
    imgUrl: "/assets/images/products/PIA.jpg",
    name: "Pinterest Ads",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "Manual",
    dialog: "AdobeAnalytics"
  },
  {
    id: 23,
    imgUrl: "/assets/images/products/GMB.jpg",
    name: "Google My Business",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "Manual",
    dialog: "AdobeAnalytics"
  },
  {
    id: 24,
    imgUrl: "/assets/images/products/SHP.jpg",
    name: "Shopify",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "Manual",
    dialog: "AdobeAnalytics"
  },
  {
    id: 25,
    imgUrl: "/assets/images/products/X.jpg",
    name: "X Organic (Twitter)",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "Manual",
    dialog: "AdobeAnalytics"
  },
  {
    id: 26,
    imgUrl: "/assets/images/products/AC.jpg",
    name: "InActiveCampaign",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "Manual",
    dialog: "AdobeAnalytics"
  },
  {
    id: 27,
    imgUrl: "/assets/images/products/AHREF.jpg",
    name: "Ahrefs",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "Manual",
    dialog: "AdobeAnalytics"
  },
  {
    id: 28,
    imgUrl: "/assets/images/products/AA.jpg",
    name: "Amazon Ads",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "Manual",
    dialog: "AdobeAnalytics"
  },
  {
    id: 29,
    imgUrl: "/assets/images/products/facebook.jpg",
    name: "Facebook Public Data",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "Manual",
    dialog: "AdobeAnalytics"
  },
  {
    id: 30,
    imgUrl: "/assets/images/products/insta.jpg",
    name: "Instagram Insights",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "Manual",
    dialog: "AdobeAnalytics"
  },
  {
    id: 31,
    imgUrl: "/assets/images/products/ADA.jpg",
    name: "Adobe Analytics",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "AppFlow",
    dialog: "AdobeAnalytics"
  },
  {
    id: 32,
    imgUrl: "/assets/images/products/GoogleAds.jpg",
    name: "Google Ads",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "AppFlow",
    dialog: "GoogleAds"
  },
  {
    id: 33,
    imgUrl: "/assets/images/products/facebook.jpg",
    name: "Facebook Ads",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "AppFlow",
    dialog: "FacebookAds"
  },
  {
    id: 34,
    imgUrl: "/assets/images/products/facebook.jpg",
    name: "Facebook Insights",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "AppFlow",
    dialog: "FacebookAds"
  },
  {
    id: 35,
    imgUrl: "/assets/images/products/GAnalytics.jpg",
    name: "Google Analytics",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "AppFlow",
    dialog: "GoogleAnalytics"
  },
  {
    id: 36,
    imgUrl: "/assets/images/products/GAnalytics.jpg",
    name: "Google Analytics 4",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "AppFlow",
    dialog: "GoogleAnalytics"
  },
  {
    id: 37,
    imgUrl: "/assets/images/products/insta.jpg",
    name: "Instagram Ads",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "AppFlow",
    dialog: "InstagramAds"
  },
  {
    id: 38,
    imgUrl: "/assets/images/products/LinkedIn.jpg",
    name: "LinkedIn Ads",
    Activity: "Active",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "SSO",
    dialog: "LinkedInAds"
  },
  {
    id: 39,
    imgUrl: "/assets/images/products/LinkedIn.jpg",
    name: "LinkedIn Pages",
    Activity: "Active",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "AppFlow",
    dialog: "LinkedInAds"
  },
  {
    id: 40,
    imgUrl: "/assets/images/products/SCM.jpg",
    name: "Snapchat Ads",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "AppFlow",
    dialog: "GoogleAnalytics"
  },
  {
    id: 41,
    imgUrl: "/assets/images/products/MARK.jpg",
    name: "Marketo",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "AppFlow",
    dialog: "Marketo"
  },
  {
    id: 42,
    imgUrl: "/assets/images/products/GSC.jpg",
    name: "Google Search Console",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "AppFlow",
    dialog: "GoogleAnalytics"
  },
  {
    id: 43,
    imgUrl: "/assets/images/products/GS.jpg",
    name: "Google Sheets",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "AppFlow",
    dialog: "GoogleAnalytics"
  },
  {
    id: 44,
    imgUrl: "/assets/images/products/BQ.jpg",
    name: "Google BigQuery",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "AppFlow",
    dialog: "GoogleBigQuery"
  },
  {
    id: 45,
    imgUrl: "/assets/images/products/HS.jpg",
    name: "HubSpot",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "AppFlow",
    dialog: "GoogleAnalytics"
  },
  {
    id: 46,
    imgUrl: "/assets/images/products/mailchimp.jpg",
    name: "MailChimp",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "AppFlow",
    dialog: "MailChimp"
  },
  {
    id: 47,
    imgUrl: "/assets/images/products/SLK.jpg",
    name: "Slack",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "AppFlow",
    dialog: "Slack"
  },
  {
    id: 48,
    imgUrl: "/assets/images/products/ST.jpg",
    name: "Stripe",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "AppFlow",
    dialog: "Stripe"
  },
  {
    id: 49,
    imgUrl: "/assets/images/products/MicrosoftAd.jpg",
    name: "Microsoft Advertising",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "AppFlow",
    dialog: "AdobeAnalytics"
  },
  {
    id: 50,
    imgUrl: "/assets/images/products/TikTok.jpg",
    name: "TikTok Ads",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "AppFlow",
    dialog: "AdobeAnalytics"
  },
  {
    id: 51,
    imgUrl: "/assets/images/products/TikTok.jpg",
    name: "TikTok Organic",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "AppFlow",
    dialog: "AdobeAnalytics"
  },
  {
    id: 52,
    imgUrl: "/assets/images/products/PIA.jpg",
    name: "Pinterest Ads",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "AppFlow",
    dialog: "AdobeAnalytics"
  },
  {
    id: 53,
    imgUrl: "/assets/images/products/GMB.jpg",
    name: "Google My Business",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "AppFlow",
    dialog: "AdobeAnalytics"
  },
  {
    id: 54,
    imgUrl: "/assets/images/products/SHP.jpg",
    name: "Shopify",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "AppFlow",
    dialog: "AdobeAnalytics"
  },
  {
    id: 55,
    imgUrl: "/assets/images/products/X.jpg",
    name: "X Organic (Twitter)",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "AppFlow",
    dialog: "AdobeAnalytics"
  },
  {
    id: 56,
    imgUrl: "/assets/images/products/AC.jpg",
    name: "InActiveCampaign",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "AppFlow",
    dialog: "AdobeAnalytics"
  },
  {
    id: 57,
    imgUrl: "/assets/images/products/AHREF.jpg",
    name: "Ahrefs",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "AppFlow",
    dialog: "AdobeAnalytics"
  },
  {
    id: 58,
    imgUrl: "/assets/images/products/AA.jpg",
    name: "Amazon Ads",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "AppFlow",
    dialog: "AdobeAnalytics"
  },
  {
    id: 59,
    imgUrl: "/assets/images/products/facebook.jpg",
    name: "Facebook Public Data",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "AppFlow",
    dialog: "AdobeAnalytics"
  },
  {
    id: 60,
    imgUrl: "/assets/images/products/insta.jpg",
    name: "Instagram Insights",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "AppFlow",
    dialog: "AdobeAnalytics"
  },
  {
    id: 61,
    imgUrl: "/assets/images/products/facebook.jpg",
    name: "Facebook Ads",
    Activity: "InActive",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "SSO",
    dialog: "FacebookAdsSSO"
  },
  {
    id: 62,
    imgUrl: "/assets/images/products/insta.jpg",
    name: "Instagram Insights",
    Activity: "Active",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "SSO",
    dialog: "InstagramInsights"
  },
  {
    id: 63,
    imgUrl: "/assets/images/products/YT.jpg",
    name: "YouTube Analytics",
    Activity: "Active",
    response: '{"stores":....',
    created: "Feb 22, 2024, 10:30 am",
    query: "clientInformation.stores.all(get_...",
    type: "SSO",
    dialog: "YouTubeAnalytics"
  }
];

Mock.onGet("/api/ActiveConn").reply(() => {
  const response = ConnectorList.filter((active) => active.Activity === "Active").sort((a, b) =>
    a.name > b.name ? 1 : -1
  );
  return [200, response];
});

Mock.onGet("/api/ActiveConns").reply((config) => {
  const response = ConnectorList.filter((active) => active.Activity === "Active")
    .filter((Ctype) => Ctype.type === config.params.Name)
    .sort((a, b) => (a.name > b.name ? 1 : -1));
  return [200, response];
});

Mock.onGet("/api/InActiveConn").reply((config) => {
  const response = ConnectorList.filter((active) => active.Activity === "InActive")
    .filter((Ctype) => Ctype.type === "AppFlow")
    .sort((a, b) => (a.name > b.name ? 1 : -1));
  return [200, response];
});

Mock.onGet("/api/InActiveConns").reply((config) => {
  const response = ConnectorList.filter((active) => active.Activity === "InActive")
    .filter((Ctype) => Ctype.type === config.params.Name)
    .sort((a, b) => (a.name > b.name ? 1 : -1));
  return [200, response];
});
