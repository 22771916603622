import { lazy } from "react";
import { Navigate } from "react-router-dom";

import AuthGuard from "./auth/AuthGuard";
import { authRoles } from "./auth/authRoles";

import Loadable from "./themecomponents/Loadable";
import MatxLayout from "./themecomponents/MatxLayout/MatxLayout";

// SESSION PAGES
const NotFound = Loadable(lazy(() => import("app/views/sessions/NotFound")));

// DASHBOARD PAGE
const DashBoard = Loadable(lazy(() => import("app/views/Dashboard/DashBoard")));
const CDSHome = Loadable(lazy(() => import("app/components/dashboard/activeConn/CDSHome")));

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      // dashboard route
      { path: "/", element: <DashBoard />, auth: authRoles.admin },
      { path: "/dashboard/activeConnections", element: <CDSHome />, auth: authRoles.admin },
    ]
  },

  // session pages route
  { path: "/session/404", element: <NotFound /> },

  { path: "/", element: <DashBoard /> },
  { path: "*", element: <NotFound /> }
];

export default routes;
