import Mock from "./mock";

import "./db/auth";
import "./db/ecommerce";
import "./db/notification";
import "./db/ConnMaster";
import "./db/Transfer";
import "./db/list";

Mock.onAny().passThrough();
